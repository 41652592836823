









import { Vue, Component, Prop } from "vue-property-decorator";
import Report, { IReport } from "@/components/status/Report.vue";

export interface IProblem {
    name: string;
    reports: IReport[];
}

@Component({
    components: {
        "report-status" : Report,
    },
})
export default class StatusProblem extends Vue {
    @Prop({ type: Object, required: false }) private problem?: IProblem;
}
