










import { Vue, Component, Prop } from "vue-property-decorator";

export interface IReport {
    status: string;
    description: string;
    date: string;
}

@Component
export default class Report extends Vue {
    @Prop({ type: Object, required: false }) private report?: IReport;
}
