var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status" }, [
    _c("div", { staticClass: "status-date" }, [_vm._v(" 07 ноября, 2019г. ")]),
    _c("div", { staticClass: "problems" }, [_c("status-problem")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }