











import { Vue, Component, Prop } from "vue-property-decorator";
import StatusProblem, { IProblem } from "@/components/status/StatusProblem.vue";

export interface IDay {
    date: string;
    problems: IProblem[];
}

@Component({
    components: {
        "status-problem": StatusProblem,
    },
})
export default class Status extends Vue {
    @Prop({ type: Object }) private day?: IDay;
}
