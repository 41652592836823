var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "problem" }, [
    _c("h3", { staticClass: "problem-name" }, [_vm._v("Сломалось что-то")]),
    _c("div", { staticClass: "reports" }, [_c("report-status")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }