






import { Vue, Component } from "vue-property-decorator";

import Status from "@/components/status/Status.vue";

@Component({
    components: {
        Status,
    },
})
export default class Monitoring extends Vue {

}
